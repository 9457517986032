import './App.css';
import React from 'react';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="https://i.redd.it/sp7o79ing0c71.png" className="App-logo" alt="logo" />
        <p>
          nice <code>coq</code> uwu
        </p>
        <a
          className="App-link"
          href="https://daarenx.github.io"
          target="_blank"
          rel="noopener noreferrer"
        >
          DaarenSite
        </a>
      </header>
    </div>
  );
}

export default App;
